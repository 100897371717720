import { LinearProgress, Typography } from "@mui/material";
import { Stack } from "@mui/system";

const ExaminationProgress = ({ currentIdx, totalQues }) => {
  return (
    <Stack spacing={2}>
      <LinearProgress
        sx={{ height: 10, borderRadius: 10 }}
        color="secondary"
        value={(currentIdx / totalQues) * 100}
        variant="determinate"
      />
      <Typography variant="h6" align="center">
        {currentIdx + 1} / {totalQues}
      </Typography>
    </Stack>
  );
};

export default ExaminationProgress;
