import { useState } from "react";

import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import ModalLayout from "./ModalLayout";
import {
  doc,
  addDoc,
  arrayUnion,
  serverTimestamp,
  collection,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";

const AddSubjectModal = ({ onClose, subject, user }) => {
  const [loading, setLoading] = useState(false);
  const [paymentMode, setPaymentMode] = useState("");

  const updateUser = async () => {
    const docRef = doc(db, `users/${user.uid}/`);
    const collectionRef = collection(db, `users/${user.uid}/payments`);
    try {
      setLoading(true);
      // updating user doc
      const docResp = await updateDoc(docRef, {
        subjects: arrayUnion(subject),
      });
      // adding payment log
      const colResp = await addDoc(collectionRef, {
        ts: new Date(),
        paymentMode,
        subject,
      });
      setTimeout(() => window.location.reload(), 1500);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <ModalLayout
      title={`Add ${subject} subject to your account`}
      onClose={onClose}
      maxWidth="xs"
    >
      <Stack mt={2}>
        <Typography mb={2}>
          After adding a subject to your account, you will be able to view and
          participate in all the upcoming/ ongoing Olympiads for that subject.
        </Typography>
        <Typography mb={1} fontWeight={700}>
          Payment mode
        </Typography>
        <RadioGroup
          onChange={(_, val) => setPaymentMode(val)}
          value={paymentMode}
        >
          <FormControlLabel value="UPI" control={<Radio />} label="UPI" />
          <Typography>
            Make payment of Rs. 150 to +91 87009-33955 and in the payment
            message, add your GOZ-ID i.e maisd10902
          </Typography>
          <FormControlLabel
            value="alreadyPaid"
            control={<Radio />}
            label="Already paid at school"
          />
          <Typography my={2}>
            If you have any queries, feel free to contact us +91 87009-33955
          </Typography>
        </RadioGroup>
        <Button
          disabled={!paymentMode || loading}
          onClick={updateUser}
          color="secondary"
          disableElevation
          size="large"
          variant="contained"
        >
          Add subject
        </Button>
      </Stack>
    </ModalLayout>
  );
};

export default AddSubjectModal;
