import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

const caps = {
  85: "Excellent job! Your performance is outstanding and indicates a high level of mastery in the subject. Keep up the great work!",
  71: "Very good job! You demonstrated a solid understanding of the subject matter and performed well in the competition. Keep practicing to improve even more!",
  50: "Good job! You have a decent grasp of the subject, but there is definitely room for improvement. Focus on identifying your weak areas and practicing more in those areas.",
  33: "Fair job. While you showed some understanding of the subject, your performance suggests that you may need more practice and study to improve. Identify areas where you struggled and work on improving in those areas.",
  0: "There is definitely room for improvement. It's important to identify the areas where you struggled the most and focus on building your knowledge and skills in those areas. Keep practicing and don't give up!",
};

const ScholasticAreas = ({ olympiads, uid }) => {
  function getRemark(percentage) {
    const perc = Math.round(percentage);
    const sortedKeys = Object.keys(caps).sort((a, b) => b - a);
    for (const key of sortedKeys) {
      if (perc >= Number(key)) {
        return caps[key];
      }
    }
  }

  return (
    <Stack my={4}>
      <Typography fontWeight={600} variant="h6">
        Scholastic Areas
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                "& > *": {
                  fontWeight: "600 !important",
                },
              }}
            >
              <TableCell>Olympiad</TableCell>
              <TableCell>Subject</TableCell>
              <TableCell>Questions</TableCell>
              <TableCell>Marks</TableCell>
              <TableCell>Rank</TableCell>
              <TableCell>Remarks</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {olympiads.map((omp) => (
              <TableRow key={omp.id}>
                <TableCell>{omp.title}</TableCell>
                <TableCell>{omp.subject}</TableCell>
                <TableCell>{omp.qCount}</TableCell>
                <TableCell>{omp.totalCorrect}</TableCell>
                <TableCell>{omp.rankings[uid]}</TableCell>
                <TableCell>
                  {getRemark((omp.totalCorrect / omp.qCount) * 100)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default ScholasticAreas;
