import { useState } from "react";
import { Typography, Stack } from "@mui/material";
import First from "./First";
import Second from "./Second";
import { useDispatch, useSelector } from "react-redux";
import { updateUserDoc } from "../../redux/slices/userSlice";

const Screens = [First, Second];

const initForm = {
  fullName: "",
  fatherName: "",
  motherName: "",
  subjects: [],
  paymentMode: "UPI",
  avatar: 0,
};

const OnboardScreen = () => {
  const [idx, setIdx] = useState(0);
  const [form, setForm] = useState(initForm);
  const dispatch = useDispatch();
  const uid = useSelector((state) => state.user.user.uid);

  const handlechange = (e) =>
    setForm((o) => ({ ...o, [e.target.name]: e.target.value }));

  const addRemoveSubject = (subject) => {
    setForm((o) => ({
      ...o,
      subjects: o.subjects.includes(subject)
        ? o.subjects.filter((f) => f !== subject)
        : o.subjects.concat(subject),
    }));
  };
  const Screen = Screens[idx];

  const nextScreen = () => {
    if (idx === Screens.length - 1) {
      dispatch(updateUserDoc({ data: form, uid }));
    } else setIdx((o) => o + 1);
  };
  return (
    <>
      <Stack
        spacing={1}
        mb={15}
        pt={6}
        align="center"
        justifyContent="space-between"
      >
        <Typography variant="h5">{idx + 1} / 2</Typography>
        <Typography color="gray" fontWeight={700} letterSpacing={0.5}>
          SETUP YOUR ACCOUNT
        </Typography>
      </Stack>
      <Screen
        addRemoveSubject={addRemoveSubject}
        {...form}
        handleChange={handlechange}
        nextScreen={nextScreen}
      />
    </>
  );
};

export default OnboardScreen;
