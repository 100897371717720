import {
  Stack,
  FormControlLabel,
  Radio,
  Grid,
  Typography,
  Divider,
  Button,
  RadioGroup,
} from "@mui/material";
import { useSelector } from "react-redux";
import SubjectChip from "./SubjectChip";
import IDCard from "../../layout/UI/IDCard";

// TODO dynamic subjects
const Second = ({
  subjects,
  addRemoveSubject,
  paymentMode,
  handleChange,
  nextScreen,
}) => {
  const subjectsGradesBasis = useSelector((state) => state.global.grades);
  const userGrade = useSelector((state) => state.user.user.grade);
  const availableSubjects = subjectsGradesBasis[userGrade];
  const { uid } = useSelector((state) => state.user.user);
  return (
    <Grid container spacing={1} justifyContent="center">
      <Grid item xs={3} alignItems="center">
        <IDCard>
          <Typography variant="h5">Enrolled subjects</Typography>
          <Divider
            sx={{
              my: 1,
              borderColor: "rgba(255,255,255,.5)",
            }}
          />
          <Stack height="80%" sx={{ overflowY: "scroll" }}>
            {!subjects.length ? (
              <Typography variant="h6">No subjects added</Typography>
            ) : (
              subjects.map((subject) => (
                <Typography key={subject} variant="h6">
                  {subject}
                </Typography>
              ))
            )}
          </Stack>
        </IDCard>
      </Grid>
      <Grid item xs={4}>
        <Stack spacing={5}>
          <Stack spacing={3}>
            <Typography variant="h6" fontWeight={700}>
              Select Subjects{" "}
              <span
                style={{
                  fontSize: 14,
                }}
              >
                (Rs.150 per subject)
              </span>
            </Typography>
            <Stack flexWrap="wrap" direction="row" gap={1}>
              {availableSubjects.map((subject) => (
                <SubjectChip
                  key={subject}
                  onClick={() => addRemoveSubject(subject)}
                  value={subject}
                  isActive={subjects.includes(subject)}
                />
              ))}
            </Stack>
          </Stack>
          <Stack spacing={2}>
            <Typography
              sx={{
                textDecoration: "underline",
              }}
              fontWeight={700}
              variant="h5"
            >
              Payment mode
            </Typography>

            <RadioGroup
              name="paymentMode"
              onChange={handleChange}
              value={paymentMode}
            >
              <FormControlLabel
                value="UPI"
                control={<Radio />}
                label={
                  <Typography fontWeight={700} variant="h6">
                    UPI
                  </Typography>
                }
              />
              <FormControlLabel
                value="alreadyPaid"
                label={
                  <Typography fontWeight={700} variant="h6">
                    Already paid at school
                  </Typography>
                }
                control={<Radio />}
              />
            </RadioGroup>
          </Stack>
          <Stack bgcolor="#F3F3F3" p={2}>
            <Typography fontWeight={700}>
              {paymentMode === "UPI"
                ? `Make payment of Rs. ${
                    150 * subjects.length
                  } to +91 87009-33955 and in the payment message, add your GOZ-ID i.e ${uid}`
                : `It is expected that you've made payment of Rs. ${
                    150 * subjects.length
                  } at school and provided your details to the administration`}
            </Typography>
          </Stack>
          <Button
            disabled={!subjects.length}
            onClick={nextScreen}
            color="secondary"
            size="large"
            disableElevation
            variant="contained"
          >
            Next
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Second;
