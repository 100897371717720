import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";

const globalColRef = collection(db, "global");

export const fetchGlobalData = createAsyncThunk(
  "global/fetchGlobalData",
  async () => {
    const data = await getDocs(globalColRef);
    return data.docs[0].data();
  }
);

const globalSlice = createSlice({
  name: "global",
  initialState: {
    schools: {},
    grades: {},
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGlobalData.fulfilled, (state, action) => {
      const schools = Object.keys(action.payload.schools).map((state) => [
        state,
        ...action.payload.schools[state],
      ]);

      state.schools = schools;
      state.grades = action.payload.grades;
    });
  },
});

export default globalSlice.reducer;
