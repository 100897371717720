// import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/AddCircle";
import {
  Badge,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";

import format from "date-fns/format";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModalContext } from "../../context/modalContext";
import CardLabel from "../../layout/UI/CardLabel";
import IDCard from "../../layout/UI/IDCard";
import ImageEl from "../../layout/UI/ImageEl";
import { fetchGlobalData } from "../../redux/slices/globalSlice";
import SubjectChip from "../Onboard/SubjectChip";
import ReportCard from "./ReportCard";

const AccountScreen = () => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("lg"));

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const subjectsGradesBasis = useSelector((state) => state.global.grades);
  const [openCert, setOpenCert] = useState(false);

  const { setModal } = useContext(ModalContext);
  const openAddSubjectModal = (subject) =>
    setModal({ name: "AddSubjectModal", props: { subject, user } });

  useEffect(() => {
    // if grades list is not already fetched
    if (!Object.keys(subjectsGradesBasis).length) {
      dispatch(fetchGlobalData());
    }
  }, []);

  const availableSubjects = subjectsGradesBasis[user.grade];

  if (!Object.keys(subjectsGradesBasis).length) return <CircularProgress />;
  return (
    <>
      {openCert && <ReportCard onClose={() => setOpenCert(false)} />}
      <Stack
        mb={{ xs: 3, sm: 6 }}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography fontWeight={700} variant="h4">
          Account
        </Typography>
        {/* <Button startIcon={<EditIcon />} disableElevation color="inherit">
          Edit Profile
        </Button> */}
      </Stack>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <IDCard>
            <ImageEl
              sx={{
                background: (theme) => theme.palette.avatarBg,
                borderRadius: "50%",
                height: 75,
                width: 75,
              }}
              src={`/assets/avatars/Avatar-${user.avatar + 1}.png`}
            />
            <CardLabel text={user.fullName} sub="student name" />
            <CardLabel text={user.school} sub="school name" />
            <CardLabel text={user.grade} sub="grade" />
            <CardLabel text={user.uid} sub="GOZ id" canCopy />
          </IDCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Stack spacing={5}>
            <CardLabel
              text={format(new Date(user.dob), "dd/MM/yyyy")}
              sub="date of birth"
              isWhite={false}
            />
            <CardLabel
              text={user.contactNumber}
              sub="Contact no."
              isWhite={false}
            />
            <CardLabel
              text={format(new Date(user.createdAt), "dd/MM/yyyy")}
              sub="Member since"
              isWhite={false}
            />
          </Stack>
        </Grid>
      </Grid>
      <Stack mt={8} mb={6}>
        <Typography variant="h5">Subjects</Typography>
        <Stack flexWrap="wrap" mt={3} direction="row" gap={3}>
          {availableSubjects.map((subject) => (
            <Badge
              key={subject}
              // onClick={
              //   !user.subjects.includes(subject) ? openAddSubjectModal : null
              // }
              {...(!user.subjects.includes(subject)
                ? {
                    badgeContent: (
                      <AddIcon
                        color="secondary"
                        onClick={() => openAddSubjectModal(subject)}
                      />
                    ),
                  }
                : {})}
            >
              <SubjectChip
                value={subject}
                isActive={user.subjects.includes(subject)}
              />
            </Badge>
          ))}
        </Stack>
      </Stack>

      {!isSm && (
        <Stack
          borderRadius={2}
          bgcolor="#F3F3F3"
          p={3}
          mb={4}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack spacing={1.5}>
            <Typography fontWeight={700} variant="h6">
              Download Report Card
            </Typography>
            <Typography>
              Download Detailed report card for all the olympiads that you’ve
              taken part in
            </Typography>
          </Stack>
          <Button
            onClick={() => setOpenCert(true)}
            size="large"
            disableElevation
            variant="contained"
          >
            Download PDF
          </Button>
        </Stack>
      )}
    </>
  );
};

export default AccountScreen;
