import { styled, TextField, outlinedInputClasses } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import sub from "date-fns/sub";

export default styled((props) => (
  <DatePicker
    maxDate={sub(new Date(), {
      years: 10,
    })}
    minDate={sub(new Date(), {
      years: 40,
    })}
    disableFuture
    renderInput={({ inputProps, ...params }) => (
      <TextField
        {...params}
        fullWidth
        inputProps={{
          ...inputProps,
          readOnly: true,
        }}
        sx={(theme) => ({
          [`.${outlinedInputClasses.root}`]: {
            ...theme.mixins.inputStyles,
          },
        })}
      />
    )}
    {...props}
  />
))({});
