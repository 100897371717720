import ModalLayout from "./ModalLayout";
import avatars from "../../utils/avatars";
import { Button, Grid, Stack } from "@mui/material";
import ImageEl from "../UI/ImageEl";
import { useState } from "react";

const ChangeAvatarModal = ({ selectedAvatar, changeAvt, onClose }) => {
  const [avt, setAvt] = useState(selectedAvatar);

  const change = () => {
    changeAvt(avt);
    onClose();
  };

  console.log(avt);

  return (
    <ModalLayout title="Change avatar" onClose={onClose}>
      <Grid
        mb={3}
        mt={1}
        container
        p={2}
        maxHeight={300}
        overflow="scroll"
        spacing={3}
      >
        {avatars.map((avatar, idx) => (
          <Grid
            key={avatar}
            item
            xs={2}
            sx={{
              cursor: "pointer",
            }}
            onClick={() => setAvt(idx)}
          >
            <Stack>
              <ImageEl
                sx={{
                  background: (theme) => theme.palette.avatarBg,
                  borderRadius: "50%",
                  border: avt === idx ? 2 : 0,
                  borderColor: avt === idx ? "primary.main" : "none",
                }}
                src={`/assets/avatars/${avatar}.png`}
              />
            </Stack>
          </Grid>
        ))}
      </Grid>

      <Button
        onClick={change}
        disableElevation
        size="large"
        variant="contained"
      >
        Select Avatar
      </Button>
    </ModalLayout>
  );
};

export default ChangeAvatarModal;
