import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import ImageEl from "../../../layout/UI/ImageEl";

const ReportCardHeader = () => {
  return (
    <Stack mb={5} alignItems="center">
      <ImageEl width={200} src="/assets/logo.svg" />
      <Typography mt={3} fontWeight={600} variant="h4">
        Report Card
      </Typography>
    </Stack>
  );
};

export default ReportCardHeader;
