import { useContext, useState } from "react";
import { Grid, Typography } from "@mui/material";
import CertificateCard from "./CertificateCard";
import { useSelector } from "react-redux";

import { getDoc, doc } from "firebase/firestore";
import { db } from "../../firebase";
import { SnackbarContext } from "../../context/SnackbarContext";

import format from "date-fns/format";
import CertificateModal from "./CertificateModal";

const CertificatesScreen = () => {
  const [olympiad, setOlympiad] = useState(null);
  const { participatedInOlympiads, ...user } = useSelector(
    (state) => state.user.user
  );

  const { showNotification } = useContext(SnackbarContext);

  const downloadCertificate = async (olympiadId) => {
    const certDocRef = doc(db, `olympiads/${olympiadId}`);
    const data = await (await getDoc(certDocRef)).data();
    if (data.active) return showNotification("Results are not out yet");
    setOlympiad({
      olympiad: data.title,
      rank: data.rankings[user.uid],
      liveAt: format(data.liveAt.toDate(), "dd MMM yyyy"),
    });
  };

  return (
    <>
      {olympiad && (
        <CertificateModal
          {...user}
          {...olympiad}
          onClose={() => setOlympiad(null)}
        />
      )}
      <Typography mb={6} fontWeight={700} variant="h4">
        Certificates
      </Typography>
      <Grid container spacing={4}>
        {participatedInOlympiads?.length ? (
          participatedInOlympiads.map((certificate) => (
            <CertificateCard
              downloadCertificate={() =>
                downloadCertificate(certificate.olympiadId)
              }
              key={certificate.olympiad}
              title={certificate.olympiad}
              olympiadId={certificate.olympiadId}
            />
          ))
        ) : (
          <Typography p={4}>No certificate for download</Typography>
        )}
      </Grid>
    </>
  );
};

export default CertificatesScreen;
