import { ThemeProvider } from "@emotion/react";
import { Box, CircularProgress, Stack, IconButton } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { onAuthStateChanged } from "firebase/auth";
import { useCallback, useEffect, useState } from "react";
// redux
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import Sidebar from "./components/Sidebar";
import { ModalContextProvider } from "./context/modalContext";
import { SnackbarProvider } from "./context/SnackbarContext";
import { auth } from "./firebase";
import ModalManager from "./layout/modals/ModalManager";
import { fetchUser, logout } from "./redux/slices/userSlice";
import theme from "./theme/theme";

// icons
import MenuIcon from "@mui/icons-material/Menu";

const App = () => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const { user, newUser } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [appload, setAppload] = useState(true);
  const { pathname } = useLocation();
  const currentPath = pathname.split("/")[1];
  const excludeFrameLayoutOnPaths = ["auth", "onboard", "examination"];
  const showInnerLayout = !excludeFrameLayoutOnPaths.includes(currentPath);

  const handleOpenSidebar = useCallback(() => setOpenSidebar(true), []);
  const handleCloseSidebar = useCallback(() => setOpenSidebar(false), []);

  useEffect(() => {
    handleCloseSidebar();
  }, [currentPath]);

  useEffect(() => {
    onAuthStateChanged(auth, async (userAuth) => {
      if (userAuth) {
        if (!newUser) {
          await dispatch(fetchUser(userAuth));
        }
        // console.log(await (await userAuth.getIdTokenResult()).claims);
      } else {
        dispatch(logout());
      }

      setAppload(false);
    });
  }, []);

  if (appload)
    return (
      <Stack alignItems="center" mt={15}>
        <CircularProgress />
      </Stack>
    );
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <SnackbarProvider>
        <ThemeProvider theme={theme}>
          <ModalContextProvider>
            <ModalManager />

            {showInnerLayout && user && (
              <Sidebar onClose={handleCloseSidebar} open={openSidebar} />
            )}
            {showInnerLayout ? (
              <Box
                pt={{ xs: 2, sm: 4 }}
                pl={{ xs: 2, md: 38 }}
                pr={{ xs: 2, md: 3 }}
              >
                <IconButton
                  onClick={handleOpenSidebar}
                  sx={{
                    mb: 2,
                    display: {
                      xs: "inline-block",
                      md: "none",
                    },
                  }}
                  size="small"
                >
                  <MenuIcon />
                </IconButton>
                <AppRoutes />
              </Box>
            ) : (
              <AppRoutes />
            )}
          </ModalContextProvider>
        </ThemeProvider>
      </SnackbarProvider>
    </LocalizationProvider>
  );
};

export default App;
