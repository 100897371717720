import { forwardRef } from "react";
import { Stack, Typography, Tooltip } from "@mui/material";
import Countdown, { zeroPad } from "react-countdown";

const ExaminationCountdown = forwardRef(
  ({ start, startTime, duration }, ref) => {
    return (
      <Countdown
        ref={ref}
        autoStart={false}
        date={startTime + duration * 60000}
        renderer={({ hours, minutes, seconds, completed }) =>
          completed ? (
            <Tooltip title="Don't worry! You can still submit your olympiad">
              <Typography
                variant="h6"
                sx={{
                  textDecoration: "underline",
                }}
              >
                Overtime
              </Typography>
            </Tooltip>
          ) : (
            <Stack alignItems="flex-end">
              <Typography variant="h5">
                {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
              </Typography>
              <Typography textTransform="uppercase" variant="caption">
                {start ? "Time left" : "Time limit"}
              </Typography>
            </Stack>
          )
        }
      />
    );
  }
);

export default ExaminationCountdown;
