import { useState, useEffect } from "react";
import { Button, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import SpanBtn from "../../layout/UI/SpanBtn";
import Login from "./Login";
import Register from "./Register";
import { auth } from "../../firebase";

import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import { fetchGlobalData } from "../../redux/slices/globalSlice";
import { createUserDoc } from "../../redux/slices/userSlice";
import { useNotification } from "../../context/SnackbarContext";
import { useTheme } from "@emotion/react";

const initForm = {
  email: "",
  password: "",
  dob: null,
  school: "",
  grade: 1,
  contactNumber: "",
};

const AuthScreen = () => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("md"));
  const [form, setForm] = useState(initForm);
  const { showNotification } = useNotification();
  const [loading, setLoading] = useState(false);
  const [tosAccepted, setTosAccepted] = useState(false);
  // login state
  const [isLogin, setIsLogin] = useState(true);
  // list of schools
  const schools = useSelector((state) => state.global.schools);

  const dispatch = useDispatch();

  const handleChange = (e) => {
    setForm((old) => ({ ...old, [e.target.name]: e.target.value }));
  };

  const handleToggleTos = () => setTosAccepted((o) => !o);

  const handleAuth = async () => {
    const phoneRegex = new RegExp(/^\d{10}$/);
    if (
      !isLogin &&
      (!form.email.trim().length ||
        !form.password.trim() ||
        !form.dob ||
        !form.school ||
        !form.contactNumber)
    ) {
      return showNotification("Please enter all the details");
    }
    if (!isLogin && !phoneRegex.test(form.contactNumber)) {
      return showNotification("Invalid phone number");
    }

    setLoading(true);
    try {
      if (isLogin) {
        await signInWithEmailAndPassword(auth, form.email, form.password);
      } else {
        const { user } = await createUserWithEmailAndPassword(
          auth,
          form.email,
          form.password
        );

        dispatch(createUserDoc({ user, form }));
        setTimeout(() => window.location.reload(), 3000);
      }
    } catch (err) {
      const msg = err.code.split("auth/")[1].split("-").join(" ");
      showNotification(msg);
      setLoading(false);
    }
  };

  useEffect(() => {
    // if schools list is not already fetched
    if (!Object.keys(schools).length) {
      dispatch(fetchGlobalData());
    }
  }, []);

  return (
    <Grid container>
      {!isSm && (
        <Grid
          item
          xs={6}
          sx={{
            background: "url(/assets/auth-pattern.png), #D26555",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            position: "relative",
          }}
        >
          <Stack
            sx={{
              width: "70%",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
              color: "#fff",
            }}
            spacing={2}
          >
            <img src="/assets/auth-highlights.png" alt="highlights" />
            <Typography fontWeight={700} variant="h4">
              India's leading no.1 CUET revisionary series &amp; online language
              olympiad platform
            </Typography>
            <Typography variant="h6" fontWeight={700}>
              Give olympiads anywhere, anytime
            </Typography>
          </Stack>
        </Grid>
      )}

      <Grid item xs={12} md={6}>
        <Stack height="100vh" alignItems="center" justifyContent="center">
          <Stack width={isSm ? "80%" : "60%"} alignItems="center" spacing={4}>
            <Stack alignItems="center" spacing={2}>
              <Stack
                borderRadius="50%"
                width="100px"
                height="100px"
                alignItems="center"
                justifyContent="center"
                border="1px solid #DCDDE0"
                bgcolor="#FAFBFE"
              >
                <img src="/assets/logo.svg" alt="logo" />
              </Stack>
              <Typography align="center">
                Get started with the best learning experience out there.
              </Typography>
            </Stack>
            {isLogin ? (
              <Login handleChange={handleChange} {...form} />
            ) : (
              <Register
                schools={schools}
                handleChange={handleChange}
                toggleTos={handleToggleTos}
                tos={tosAccepted}
                {...form}
              />
            )}
            <Button
              onClick={handleAuth}
              disabled={loading || (!isLogin && !tosAccepted)}
              disableElevation
              size="large"
              fullWidth
              color="primary"
              variant="contained"
              sx={{
                fontSize: 18,
              }}
            >
              {isLogin ? "Login" : "Register"}
            </Button>
            <Stack>
              <Typography>
                {isLogin
                  ? "Don't have an account?"
                  : "Already have an account?"}{" "}
                <SpanBtn onClick={() => setIsLogin((o) => !o)}>
                  {isLogin ? "Sign up" : "Sign in"}
                </SpanBtn>
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default AuthScreen;
