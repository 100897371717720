import {
  Drawer,
  Typography,
  useTheme,
  Button,
  useMediaQuery,
} from "@mui/material";
import { Stack } from "@mui/material";
import OlympiadsIcon from "../../icons/OlympiadsIcon";
// import CertificatesIcon from "../../icons/CertificatesIcon";
import AccountIcon from "../../icons/AccountIcon";
import LogoutIcon from "../../icons/LogoutIcon";
import { NavLink } from "react-router-dom";
import ImageEl from "../../layout/UI/ImageEl";
import { useSelector } from "react-redux";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";

const Sidebar = ({ open, onClose }) => {
  const user = useSelector((state) => state.user.user);
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Drawer
      open={open}
      onClose={onClose}
      variant={isSm ? "temporary" : "permanent"}
      PaperProps={{
        sx: {
          p: 3,
          py: 5,
          width: 220,
          border: "none",
          boxShadow: "4px 0px 30px rgba(0, 0, 0, 0.05)",
        },
      }}
    >
      <Stack alignItems="center" spacing={2}>
        <ImageEl
          sx={{
            background: (theme) => theme.palette.avatarBg,
            borderRadius: "50%",
            height: 100,
            width: 100,
          }}
          src={`/assets/avatars/Avatar-${user.avatar + 1}.png`}
        />
        <Typography fontWeight={700} variant="h6">
          {user.fullName}
        </Typography>
      </Stack>
      <Stack
        mt={5}
        spacing={3}
        sx={{
          "& .MuiButton-root": {
            p: 2,
          },
        }}
      >
        <Button
          disableRipple
          to="/"
          component={NavLink}
          end
          color="inherit"
          startIcon={<OlympiadsIcon />}
          size="large"
        >
          Olympiads
        </Button>
        {/* <Button
          disableRipple
          to="/certificates"
          component={NavLink}
          end
          color="inherit"
          startIcon={<CertificatesIcon />}
          size="large"
        >
          Certificates
        </Button> */}
        <Button
          disableRipple
          to="/account"
          component={NavLink}
          end
          color="inherit"
          startIcon={<AccountIcon />}
          size="large"
        >
          Account
        </Button>
        <Button
          onClick={() => signOut(auth)}
          disableRipple
          color="inherit"
          startIcon={<LogoutIcon />}
          size="large"
        >
          Logout
        </Button>
      </Stack>
    </Drawer>
  );
};

// [logo change karna hai]

export default Sidebar;
