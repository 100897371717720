import { Stack } from "@mui/material";

const IDCard = ({ children }) => {
  return (
    <Stack
      height={450}
      spacing={3}
      sx={{
        py: 4,
        px: 3,
        boxSizing: "border-box",
        borderRadius: 4,
        width: 310,
        background:
          "url(/assets/id-pattern.png),linear-gradient(155.63deg, #020D46 6.94%, #39446D 99.34%)",
        color: "#fff",
        borderBottom: "15px solid #EC6E46",
      }}
    >
      {children}
    </Stack>
  );
};

export default IDCard;
