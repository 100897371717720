import { alpha, createTheme } from "@mui/material";

// font-family:
// font-family: 'Lato', sans-serif;

const brightColor = "#F56A32";

export default createTheme({
  mixins: {
    inputStyles: {
      color: "#78797C",
      fontWeight: 700,
      bgcolor: "#FAFBFE",
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          "&.active": {
            backgroundColor: "#EDEFF0",
          },
        },
        startIcon: {
          marginRight: 16,
        },
      },
      variants: [
        {
          props: { variant: "bright" },
          style: {
            color: "#fff",
            background: "#F56A32 !important",
          },
        },
      ],
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontWeight: 700,
        },
      },
    },
    MuiTypography: {
      variants: [
        {
          props: {
            variant: "badge",
          },
          style: {
            fontFamily: "Lato",
            color: brightColor,
            padding: "4px 16px",
            fontWeight: 700,
            borderRadius: 40,
            background: alpha(brightColor, 0.15),
          },
        },
      ],
    },
  },
  palette: {
    primary: {
      main: brightColor,
    },
    secondary: {
      main: "#1E295A",
    },
    text: {
      primary: "#505154",
    },
    avatarBg: "linear-gradient(180deg, #ECCBD2 0%, #EEBEC8 100%)",
  },
  typography: {
    fontFamily: "IBM Plex Sans, sans-serif",
    button: {
      fontFamily: "Lato",
      textTransform: "unset",
      fontWeight: 700,
    },
    h5: {
      fontWeight: 700,
    },
  },
  shape: {
    borderRadius: 4,
  },
});
