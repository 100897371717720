import EditIcon from "@mui/icons-material/Edit";
import { Badge, Button, Grid, Stack } from "@mui/material";
import { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModalContext } from "../../context/modalContext";
import StyledFilledInput from "../../layout/Input/StyledFilledInput";
import CardLabel from "../../layout/UI/CardLabel";
import IDCard from "../../layout/UI/IDCard";
import ImageEl from "../../layout/UI/ImageEl";
import { fetchGlobalData } from "../../redux/slices/globalSlice";
import avatars from "../../utils/avatars";

const fGrades = [
  "I",
  "II",
  "III",
  "IV",
  "V",
  "VI",
  "VII",
  "VIII",
  "IX",
  "X",
  "XI",
  "XII",
];

const First = ({
  nextScreen,
  handleChange,
  fullName,
  fatherName,
  motherName,
  avatar: selectedAvatar,
}) => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const { setModal } = useContext(ModalContext);
  const schools = useSelector((state) => state.global.schools);
  const { uid, school, grade } = useSelector((state) => state.user.user);

  useEffect(() => {
    // if schools list is not already fetched
    if (!Object.keys(schools).length) {
      dispatch(fetchGlobalData());
    }
  }, []);

  const handleNextScreen = () => {
    const err = {};
    const fields = {
      fullName,
      fatherName,
      motherName,
    };
    Object.keys(fields).forEach((field) => {
      if (fields[field].trim().split(" ").length !== 2)
        err[field] = "Please enter full name";
    });
    if (!!Object.keys(err).length) return setErrors(err);
    nextScreen();
  };
  const changeAvatar = () => {
    setModal({
      name: "ChangeAvatarModal",
      props: {
        selectedAvatar,
        changeAvt: (avatar) =>
          handleChange({
            target: {
              name: "avatar",
              value: avatar,
            },
          }),
      },
    });
  };
  return (
    <Grid container spacing={1} justifyContent="center">
      <Grid container item xs={3} alignItems="center">
        <IDCard>
          <ImageEl
            sx={{
              background: (theme) => theme.palette.avatarBg,
              borderRadius: "50%",
              height: 75,
              width: 75,
            }}
            src={`/assets/avatars/${avatars[selectedAvatar]}.png`}
          />
          <CardLabel
            text={fullName ? fullName : "John Doe"}
            sub="student name"
          />
          <CardLabel text={school} sub="school name" />
          <CardLabel text={fGrades[grade - 1]} sub="grade" />
          <CardLabel text={uid} sub="student id" />
        </IDCard>
      </Grid>
      <Grid item xs={3}>
        <Stack spacing={4}>
          <Stack alignItems="flex-start">
            <Badge
              onClick={changeAvatar}
              overlap="circular"
              badgeContent={<EditIcon fontSize="26px" />}
              anchorOrigin={{ horizontal: "right", vertical: "top" }}
              sx={{
                ".MuiBadge-badge": {
                  bgcolor: "#fff",
                  p: 0,
                  height: "25px",
                  width: "25px",
                  borderRadius: "50%",
                  boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.10)",
                  cursor: "pointer",
                },
              }}
            >
              <ImageEl
                sx={{
                  background: (theme) => theme.palette.avatarBg,
                  borderRadius: "50%",
                  height: 75,
                  width: 75,
                }}
                src={`/assets/avatars/${avatars[selectedAvatar]}.png`}
              />
            </Badge>
          </Stack>
          <StyledFilledInput
            helperText={errors.fullName}
            error={!!errors.fullName}
            value={fullName}
            name="fullName"
            onChange={handleChange}
            label="Full name"
          />
          <StyledFilledInput
            helperText={errors.fatherName}
            error={!!errors.fatherName}
            value={fatherName}
            name="fatherName"
            onChange={handleChange}
            label="Father's name"
          />
          <StyledFilledInput
            helperText={errors.motherName}
            error={!!errors.motherName}
            value={motherName}
            name="motherName"
            onChange={handleChange}
            label="Mother's name"
          />
          <Button
            onClick={handleNextScreen}
            color="secondary"
            size="large"
            disableElevation
            variant="contained"
          >
            Next
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default First;
