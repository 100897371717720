import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import format from "date-fns/format";

const ReportCardStudentDetails = ({ user, uid }) => {
  return (
    <TableContainer>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Student name: {user.fullName}</TableCell>
            <TableCell>
              School: {user.school}, {user.state}
            </TableCell>
            <TableCell>Grade: {user.grade}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              Date of Birth: {format(new Date(user.dob), "dd/MMM/yyyy")}
            </TableCell>
            <TableCell>GOZ ID: {uid}</TableCell>
            <TableCell />
          </TableRow>
          <TableRow>
            <TableCell>Father's name: {user.fatherName}</TableCell>
            <TableCell>Mothers's name: {user.motherName}</TableCell>
            <TableCell />
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReportCardStudentDetails;
