import { Grid, Typography, CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOlympiads } from "../../redux/slices/olympiadSlice";
import OlympiadCard from "./OlympiadCard";

const OlympiadsScreen = () => {
  const { olympiads, loading, fetched } = useSelector(
    (state) => state.olympiad
  );
  const { participatedIn } = useSelector((state) => state.user.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!fetched) {
      dispatch(fetchOlympiads());
    }
  }, []);
  return (
    <>
      <Typography fontWeight={700} variant="h4" mb={6}>
        Olympiads
      </Typography>
      <Grid container spacing={4}>
        {loading ? (
          <CircularProgress />
        ) : !olympiads.length ? (
          <Typography mt={4} pl={4}>
            No olympiads available
          </Typography>
        ) : (
          olympiads.map((olympiad, idx) => (
            <OlympiadCard
              key={olympiad.id}
              pic={idx % 2}
              {...olympiad}
              alreadyParticipated={participatedIn.includes(olympiad.id)}
            />
          ))
        )}
      </Grid>
    </>
  );
};

export default OlympiadsScreen;
