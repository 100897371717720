import { Stack, Button, Grid, Typography } from "@mui/material";
import addMinutes from "date-fns/addMinutes";
import isWithinInterval from "date-fns/isWithinInterval";
import isBefore from "date-fns/isBefore";
import format from "date-fns/format";
import ImageEl from "../../layout/UI/ImageEl";
import { useNavigate } from "react-router-dom";

const OlympiadCard = ({
  pic,
  id,
  subject,
  title,
  qCount,
  duration,
  liveAt,
  alreadyParticipated,
}) => {
  const navigate = useNavigate();
  const liveAtTs = new Date(liveAt);
  //  TIME ALLOW
  const lastTsToParticipte = addMinutes(liveAtTs, 30);
  const isBeforeLastTs = isBefore(lastTsToParticipte, new Date());

  // user can only start an olympiad if current timestamp is between the [liveAt] timestamp is between liveAt + duration(min)
  const isAvailable = isWithinInterval(new Date(), {
    start: liveAtTs,
    end: lastTsToParticipte,
  });

  // [todo] - if already participated - add label, already participated

  return (
    <Grid item xs={12} sm={6} md={3}>
      <Stack
        boxShadow="0px 5px 25px rgba(0, 0, 0, 0.05)"
        borderRadius={4}
        bgcolor="white"
        overflow="hidden"
        sx={{
          cursor: "pointer",
        }}
      >
        <ImageEl src={`/assets/olympiad-patterns/${pic}.svg`} />
        <Stack p={2}>
          <Stack alignItems="flex-start" spacing={2} mb={6}>
            <Typography fontWeight={700} variant="h6">
              {title}
            </Typography>
            <Typography variant="badge">{subject}</Typography>
          </Stack>
          <Typography fontWeight={700}>
            Q.{qCount} | {duration} min
          </Typography>
        </Stack>
        <Button
          sx={{
            mt: "auto",
            p: 1.5,
            fontSize: 17,
          }}
          disableElevation
          disabled={!isAvailable || isBeforeLastTs || alreadyParticipated}
          variant={isAvailable && !alreadyParticipated ? "bright" : "contained"}
          size="large"
          onClick={() => navigate(`/examination/${id}`)}
        >
          {alreadyParticipated
            ? "Already participated"
            : isAvailable
            ? "Live now!"
            : isBeforeLastTs
            ? "Cannot participate now"
            : `Live @ ${format(liveAtTs, "dd MMM - hh:mm a")}`}
        </Button>
      </Stack>
    </Grid>
  );
};

export default OlympiadCard;
