import { Dialog, Typography, Stack, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ModalLayout = ({ title, onClose, children, maxWidth = "sm" }) => {
  return (
    <Dialog
      onClose={onClose}
      fullWidth
      maxWidth={maxWidth}
      open
      PaperProps={{
        sx: {
          borderTop: 10,
          borderColor: "primary.main",
          p: 3,
          maxHeight: 500,
        },
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h5">{title}</Typography>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </Stack>
      {children}
    </Dialog>
  );
};

export default ModalLayout;
