import { Chip } from "@mui/material";

const SubjectChip = ({ value, isActive = false, onClick }) => {
  return (
    <Chip
      onClick={onClick}
      variant={isActive ? "filled" : "outlined"}
      sx={{
        cursor: "pointer",
      }}
      label={value}
    />
  );
};

export default SubjectChip;
