import { useRef } from "react";
import { Typography, Stack, Dialog } from "@mui/material";
import ImageEl from "../../layout/UI/ImageEl";
import CertificateHeader from "./CertificateHeader";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

const BoldText = (props) => (
  <Typography
    variant="h5"
    {...props}
    component="span"
    fontWeight={700}
    textDecoration="underline"
  />
);

const CertificateModal = ({
  fullName,
  grade,
  school,
  state,
  uid: gozId,
  olympiad,
  rank,
  liveAt,
  onClose,
}) => {
  const certRef = useRef();
  const handleDownloadCertificate = async () => {
    const element = certRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/png");

    const pdf = new jsPDF({ orientation: "landscape" });
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("print.pdf");
  };

  return (
    <Dialog fullScreen open>
      <CertificateHeader
        title="Download Certificate"
        download={handleDownloadCertificate}
        onClose={onClose}
      />
      <Stack mt={15} height="100%" alignItems="center">
        <Stack
          ref={certRef}
          sx={{
            border: "10px solid #F05640",
            outline: "10px solid #3B5B70",
          }}
          height="90%"
          width="90%"
        >
          <Stack p={8} direction="row" alignItems="center" spacing={8}>
            <ImageEl
              sx={{
                height: "80px",
                width: "auto",
              }}
              src="/assets/logo.svg"
            />
            <Typography variant="h2">Certificate of Participation</Typography>
          </Stack>

          <Stack p={4} spacing={2}>
            <Typography variant="h5" fontWeight={400}>
              This is to certify that <BoldText>{fullName}</BoldText>
            </Typography>
            <Typography variant="h5" fontWeight={400}>
              Grade: <BoldText>{grade}</BoldText>
            </Typography>
            <Typography variant="h5" fontWeight={400}>
              GOZ ID: <BoldText>{gozId}</BoldText>
            </Typography>
            <Typography variant="h5" fontWeight={400}>
              School:{" "}
              <BoldText>
                {school}, {state}
              </BoldText>
            </Typography>
            <Typography variant="h5" fontWeight={400}>
              has secured rank <BoldText>{rank}</BoldText> in the{" "}
              <BoldText>{olympiad}</BoldText> which was conducted on{" "}
              <BoldText>{liveAt}</BoldText>
            </Typography>
            <Typography variant="h5" fontWeight={400}>
              He/ She is awarded this certificate in recognition of his/ her
              performance excellence in this competition
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default CertificateModal;
