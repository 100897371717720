import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

// /onboard
const PrivateRoute = ({ children }) => {
  const { pathname } = useLocation();
  const visitingOnboard = pathname === "/onboard";
  const isAuthenticated = useSelector((state) => !!state.user.user);
  const isOnboarded = useSelector((state) => !!state.user?.user?.fullName);
  if (!isAuthenticated) return <Navigate to="/auth" />;
  if (isAuthenticated && !isOnboarded) {
    if (visitingOnboard) return children;
    else return <Navigate to="/onboard" />;
  }
  if (isOnboarded && visitingOnboard) return <Navigate to="/" />;
  return children;
};

export default PrivateRoute;
