export default [
  "Avatar-1",
  "Avatar-2",
  "Avatar-3",
  "Avatar-4",
  "Avatar-5",
  "Avatar-6",
  "Avatar-7",
  "Avatar-8",
  "Avatar-9",
  "Avatar-10",
  "Avatar-11",
  "Avatar-12",
  "Avatar-13",
  "Avatar-14",
  "Avatar-15",
  "Avatar-16",
  "Avatar-17",
  "Avatar-18",
  "Avatar-19",
  "Avatar-20",
  "Avatar-21",
  "Avatar-22",
  "Avatar-23",
  "Avatar-24",
  "Avatar-25",
  "Avatar-26",
  "Avatar-27",
  "Avatar-28",
  "Avatar-29",
  "Avatar-30",
  "Avatar-31",
  "Avatar-32",
  "Avatar-33",
  "Avatar-34",
  "Avatar-35",
  "Avatar-36",
];
