import { useContext } from "react";
import { ModalContext } from "../../context/modalContext";
import AddSubjectModal from "./AddSubjectModal";
import ChangeAvatarModal from "./ChangeAvatarModal";

const Modals = { ChangeAvatarModal, AddSubjectModal };

const ModalManager = () => {
  const { modal, setModal } = useContext(ModalContext);
  const onClose = () => setModal({});
  const Modal = Modals[modal.name];
  if (!Modal) return null;
  return <Modal {...modal.props} onClose={onClose} />;
};

export default ModalManager;
