import StyledFilledInput from "../../layout/Input/StyledFilledInput";
import StyledSelect from "../../layout/Input/StyledSelect";
import StyledDatePicker from "../../layout/Input/StyledDatePicker";
import { Checkbox, Typography, Stack } from "@mui/material";
import SpanBtn from "../../layout/UI/SpanBtn";

const Register = ({
  schools,
  email,
  password,
  dob,
  school,
  grade,
  contactNumber,
  tosAccepted,
  toggleTos,
  handleChange,
}) => {
  return (
    <>
      <StyledFilledInput
        name="email"
        value={email}
        onChange={handleChange}
        fullWidth
        label="Email"
      />
      <StyledFilledInput
        type="password"
        name="password"
        value={password}
        onChange={handleChange}
        fullWidth
        label="Password"
      />
      <StyledDatePicker
        label="Date of birth"
        value={dob}
        onChange={(newDate) => {
          handleChange({
            target: {
              name: "dob",
              value: newDate,
            },
          });
        }}
      />
      <StyledSelect
        name="school"
        value={school}
        containHeaders
        label="Schools"
        onChange={handleChange}
        options={schools}
      />
      <StyledSelect
        label="Grade"
        name="grade"
        value={grade}
        onChange={handleChange}
        options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
      />
      <StyledFilledInput
        name="contactNumber"
        value={contactNumber}
        onChange={handleChange}
        fullWidth
        label="Parent/ Guardian Number"
      />
      <Stack
        direction="row"
        alignItems="flex-start"
        justifyContent="flex-start"
        spacing={1}
      >
        <Checkbox checked={tosAccepted} onClick={toggleTos} />
        <Typography>
          I agree to the <SpanBtn>Terms and Condition</SpanBtn> and{" "}
          <SpanBtn>Privacy Policy</SpanBtn>
          <br />
          Users below age 18 are required to have consent of their parents or
          guardian.
        </Typography>
      </Stack>
    </>
  );
};

export default Register;
