import { Stack, Typography, IconButton } from "@mui/material";
import CopyIcon from "@mui/icons-material/ContentCopy";
import copy from "copy-to-clipboard";
import { useNotification } from "../../context/SnackbarContext";

const CardLabel = ({ text, sub, isWhite = true, canCopy = false }) => {
  const { showNotification } = useNotification();
  const handleCopy = () => {
    copy(text);
    showNotification(`${sub} copied to the clipboard`);
  };
  return (
    <Stack>
      <Stack direction="row">
        <Typography noWrap textOverflow="ellipsis" variant="h5">
          {text}
        </Typography>
        {canCopy && (
          <IconButton onClick={handleCopy} size="small" color="inherit">
            <CopyIcon />
          </IconButton>
        )}
      </Stack>
      <Typography
        sx={{
          textTransform: "uppercase",
          fontSize: 14,
          color: isWhite ? "rgba(255,255,255,.85)" : "#000",
        }}
      >
        {sub}
      </Typography>
    </Stack>
  );
};

export default CardLabel;
