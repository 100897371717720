import { useTheme } from "@emotion/react";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { Stack } from "@mui/system";
import { useState } from "react";

const ExaminationNote = ({ startOlympiad, olympiad }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const [showPartners, setShowPartners] = useState(false);
  return (
    <>
      <Stack my={5} alignItems="center" spacing={2}>
        {!showPartners ? (
          <>
            <Typography fontWeight={700} variant="h4">
              {olympiad.title}
            </Typography>
            <Typography variant="badge">{olympiad.subject}</Typography>
            <Typography>
              {olympiad.qCount} Questions - {olympiad.duration} min
            </Typography>
          </>
        ) : (
          <Typography variant={isMd ? "h6" : "h3"}>Our Partners</Typography>
        )}
      </Stack>
      {!showPartners ? (
        <Stack
          borderRadius={2}
          bgcolor="#F6F6F6"
          p={4}
          alignItems="center"
          spacing={3}
        >
          <Typography lineHeight={2} fontWeight={700} align="center">
            <u>Important Note</u>
            <br />
            Once you start the olympiad, it is expected that you finish it
            within {olympiad.duration} min <br />
            You cannot pause/ hold the olympiad.
          </Typography>
          <Button
            onClick={() => setShowPartners(true)}
            size="large"
            disableElevation
            color="secondary"
            variant="contained"
          >
            I understand
          </Button>
        </Stack>
      ) : (
        <Stack spacing={5} alignItems="center">
          <Box
            sx={{
              width: "100%",
              height: "150px",
              backgroundImage: "url(https://i.ibb.co/4Psv1ML/goz-schools.png)",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
          />
          <Button
            onClick={startOlympiad}
            size="large"
            disableElevation
            color="secondary"
            variant="contained"
          >
            Start Olympiad
          </Button>
        </Stack>
      )}
    </>
  );
};

export default ExaminationNote;
