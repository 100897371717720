import { useState, useContext, createContext } from "react";
import Snackbar from "@mui/material/Snackbar";

export const SnackbarContext = createContext();

export const SnackbarProvider = ({ children }) => {
  const [message, setMessage] = useState("");

  const showNotification = (txt) => setMessage(txt);

  return (
    <SnackbarContext.Provider
      value={{
        showNotification,
      }}
    >
      <Snackbar
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
        message={message}
        open={!!message}
        onClose={() => setMessage("")}
        autoHideDuration={3000}
      />
      {children}
    </SnackbarContext.Provider>
  );
};

export const useNotification = () => useContext(SnackbarContext);
