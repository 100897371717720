import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const PublicOnlyRoute = ({ children }) => {
  const isAuthenticated = useSelector((state) => !!state.user.user);
  const isOnboarded = useSelector((state) => !!state.user?.user?.fullName);
  return isAuthenticated ? (
    <Navigate to={isOnboarded ? "/" : "/onboard"} />
  ) : (
    children
  );
};

export default PublicOnlyRoute;
