import { Typography, styled } from "@mui/material";

export default styled((props) => (
  <Typography
    {...props}
    component="span"
    color="primary"
    sx={{
      fontWeight: 700,
      textDecoration: "underline",
      cursor: "pointer",
    }}
  />
))({});
