import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { db } from "../../firebase";
import {
  doc,
  setDoc,
  getDoc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import dayjs from "dayjs";
import { getAuth } from "firebase/auth";
import { submitOlympiad } from "./olympiadSlice";

export const createUserDoc = createAsyncThunk(
  "user/createUserDoc",
  async ({ user, form }) => {
    const docRef = doc(db, "users", user.uid);
    const { email, password, dob, school, ...usersInfo } = form;
    const formattedDob = dayjs(dob).toDate();
    const [state, fSchool] = school.split("--");
    const userDoc = {
      ...usersInfo,
      state,
      dob: formattedDob,
      school: fSchool,
      createdAt: serverTimestamp(),
      participatedIn: [],
    };
    await setDoc(docRef, userDoc);
    return {
      ...userDoc,
      dob: userDoc.dob.toISOString(),
      createdAt: new Date().toISOString(),
      school,
    };
  }
);

export const updateUserDoc = createAsyncThunk(
  "user/updateUserDoc",
  async ({ data, uid }) => {
    const docRef = doc(db, "users", uid);
    await updateDoc(docRef, data);
    const auth = getAuth();
    setTimeout(async () => {
      await auth.currentUser.getIdToken(true);
    }, 5000);
    return data;
  }
);

export const fetchUser = createAsyncThunk(
  "user/fetchUser",
  async ({ uid, email }) => {
    const docRef = doc(db, "users", uid);
    const { paymentLogs, ...data } = await (await getDoc(docRef)).data();
    return {
      ...data,
      ...(data.dob ? { dob: data.dob?.toDate()?.toISOString() } : {}),
      createdAt: data.createdAt.toDate().toISOString(),
      email,
      uid,
    };
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    loading: false,
    newUser: false,
  },
  reducers: {
    logout: (state) => {
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    // update user doc
    builder.addCase(updateUserDoc.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateUserDoc.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.user = {
        ...state.user,
        ...payload,
      };
    });

    // create user doc
    builder.addCase(createUserDoc.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createUserDoc.fulfilled, (state, { payload }) => {
      state.newUser = true;
      state.loading = false;
    });
    builder.addCase(fetchUser.fulfilled, (state, { payload }) => {
      state.user = {
        ...payload,
      };
    });

    // submit olympiad
    builder.addCase(submitOlympiad.fulfilled, (state, { payload }) => {
      console.log({ payload });
      state.user.participatedIn.push(payload.olympiadId);
    });
  },
});

export const { login, logout } = userSlice.actions;
export default userSlice.reducer;
