import { Button, Typography, Stack, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import StyledRadioBtn from "../../layout/Input/StyledRadioBtn";
import ImageEl from "../../layout/UI/ImageEl";

const Question = ({
  question: { ques, quesImg, options },
  submitAnswer,
  submitting,
}) => {
  const [selected, setSelected] = useState(null);
  useEffect(() => {
    setSelected(null);
  }, [ques]);

  return (
    <Stack alignItems="center" my={6}>
      {!!quesImg && (
        <ImageEl
          sx={{
            maxHeight: 350,
            maxWidth: "100%",
            mb: 4,
          }}
          src={quesImg}
        />
      )}
      <Typography variant="h5">{ques}</Typography>
      <Stack
        justifyContent="center"
        px={2}
        my={5}
        direction="row"
        gap={3}
        flexWrap="wrap"
      >
        {options.map((option, idx) => (
          <StyledRadioBtn
            key={option.opt}
            onClick={() => setSelected(idx)}
            isActive={idx === selected}
            value={option.opt}
          />
        ))}
      </Stack>
      <Button
        disabled={submitting || typeof selected !== "number"}
        onClick={() => submitAnswer(selected)}
        disableElevation
        size="large"
        color="secondary"
        variant="contained"
      >
        {submitting ? <CircularProgress color="inherit" size={20} /> : "Next"}
      </Button>
    </Stack>
  );
};

export default Question;
