import { Routes, Route } from "react-router-dom";
import AuthScreen from "./components/Auth";
import OnboardScreen from "./components/Onboard";
import OlympiadsScreen from "./components/Olympiads";
import AccountScreen from "./components/Account";
import CertificatesScreen from "./components/Certificates";
import ExaminationScreen from "./components/Examination";
import PrivateRoute from "./components/utils/PrivateRoute";
import PublicOnlyRoute from "./components/utils/PublicOnlyRoute";

const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path="/auth"
        element={
          <PublicOnlyRoute>
            <AuthScreen />
          </PublicOnlyRoute>
        }
      />
      <Route
        path="/onboard"
        element={
          <PrivateRoute>
            <OnboardScreen />
          </PrivateRoute>
        }
      />
      <Route
        path="/"
        element={
          <PrivateRoute>
            <OlympiadsScreen />
          </PrivateRoute>
        }
      />
      <Route
        path="/examination/:olympiadId"
        element={
          <PrivateRoute>
            <ExaminationScreen />
          </PrivateRoute>
        }
      />
      <Route
        path="/account"
        element={
          <PrivateRoute>
            <AccountScreen />
          </PrivateRoute>
        }
      />
      <Route
        path="/certificates"
        element={
          <PrivateRoute>
            <CertificatesScreen />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
