import { CircularProgress, Container, Dialog, Stack } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { db } from "../../../firebase";
import CertificateHeader from "../../Certificates/CertificateHeader";
import ReportCardHeader from "./ReportCardHeader";
import ReportCardStudentDetails from "./ReportCardStudentDetails";
import ScholasticAreas from "./ScholasticAreas";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

const ReportCard = ({ onClose }) => {
  const certRef = useRef();
  const { participatedInOlympiads, uid, ...user } = useSelector(
    (state) => state.user.user
  );

  const [error, setError] = useState(true);
  const [loading, setLoading] = useState(true);
  const [olympiads, setOlympiads] = useState([]);

  const fetchOlympiadDoc = async (olympiadId) => {
    const q = doc(db, `olympiads/${olympiadId}`);
    const data = await (await getDoc(q)).data();
    return { ...data, id: olympiadId };
  };

  const fetchUserEntry = async (olympiadId) => {
    const q = doc(db, `olympiads/${olympiadId}/entries/${uid}`);
    const data = await (await getDoc(q)).data();
    return { ...data, id: olympiadId };
  };

  const fetchResults = async () => {
    try {
      const olympiadsDocs = await Promise.all(
        participatedInOlympiads.map((omp) => fetchOlympiadDoc(omp.olympiadId))
      );
      const userEntriesDocs = await Promise.all(
        participatedInOlympiads.map((omp) => fetchUserEntry(omp.olympiadId))
      );

      const omps = olympiadsDocs.map((omp) => {
        const totalCorrect = userEntriesDocs.find(
          (o) => o.id === omp.id
        ).totalCorrect;
        return { ...omp, totalCorrect };
      });
      setOlympiads(omps);
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadCertificate = async () => {
    const element = certRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/png");

    const pdf = new jsPDF({ orientation: "landscape" });
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("print.pdf");
  };

  useEffect(() => {
    const fetchTop = async () => {
      try {
        fetchResults();
      } catch (err) {
        console.log(err);
      }
    };

    fetchTop();
  }, []);

  return (
    <Dialog fullScreen open>
      <CertificateHeader
        title="Report Card"
        download={handleDownloadCertificate}
        onClose={onClose}
      />

      {loading ? (
        <Stack mt={25} alignItems="center">
          <CircularProgress />
        </Stack>
      ) : (
        <Container
          ref={certRef}
          sx={{
            mt: 15,
            p: 5,
            border: "1.5px solid",
            borderColor: "red",
            alignItems: "center",
          }}
          maxWidth="lg"
        >
          <ReportCardHeader />
          <ReportCardStudentDetails user={user} uid={uid} />
          <ScholasticAreas olympiads={olympiads} uid={uid} />
        </Container>
      )}
    </Dialog>
  );
};

export default ReportCard;
