import { Button, Stack, Container } from "@mui/material";
import BackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import ExaminationNote from "./ExaminationNote";
import Question from "./Question";

import ExaminationProgress from "./ExaminationProgress";
import ExaminationCountdown from "./ExaminationCountdown";
import { differenceInSeconds } from "date-fns/esm";
import { submitOlympiad } from "../../redux/slices/olympiadSlice";

// const CountdownRender = ({ hours, minutes, seconds }) => (

// );

const ExaminationScreen = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const countdownRef = useRef();
  const initialTime = useRef(Date.now());
  const dispatch = useDispatch();

  const [startTime, setStartTime] = useState(null);

  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [currentIdx, setCurrentIdx] = useState(0);
  const [start, setStart] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  // olympiad
  const olympiadId = pathname.split("/")[2];
  const olympiads = useSelector((state) => state.olympiad.olympiads);
  const olympiad = olympiads.find((o) => o.id === olympiadId);

  const handleSubmitAnswer = async (ans) => {
    if (questions.length - 1 === currentIdx) {
      setSubmitting(true);
      await dispatch(
        submitOlympiad({
          olympiadId,
          answers: [...answers, ans],
          startTime,
          timeTaken: differenceInSeconds(new Date(), startTime),
        })
      );
      window.alert("Olympiad submitted");
      navigate("/");
    } else {
      setAnswers((o) => [...o, ans]);
      setCurrentIdx((o) => o + 1);
    }
  };

  useEffect(() => {
    if (start) {
      const fetchQuestions = async () => {
        const docRef = doc(db, "questionnaire", olympiadId);
        const { questions: ques } = await (await getDoc(docRef)).data();
        setQuestions(ques);
        countdownRef.current.api.start();
      };
      setStartTime(new Date());
      fetchQuestions();
    }
  }, [start]);

  return (
    <>
      <Stack
        height={60}
        m={2}
        mb={6}
        alignItems="center"
        direction="row"
        justifyContent="space-between"
      >
        <span />
        {!submitting && (
          <ExaminationCountdown
            duration={olympiad.duration}
            startTime={initialTime.current}
            ref={countdownRef}
            start={start}
          />
        )}
      </Stack>

      <Container maxWidth="md">
        {!!questions.length && (
          <ExaminationProgress
            currentIdx={currentIdx}
            totalQues={questions.length}
          />
        )}
        {!questions.length ? (
          <ExaminationNote
            olympiad={olympiad}
            startOlympiad={() => setStart(true)}
          />
        ) : (
          <Question
            submitting={submitting}
            submitAnswer={handleSubmitAnswer}
            question={questions[currentIdx]}
          />
        )}
      </Container>
    </>
  );
};

export default ExaminationScreen;
