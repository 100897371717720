import {
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
} from "@mui/material";

const StyledSelect = ({ options, label, containHeaders = false, ...props }) => {
  return (
    <FormControl fullWidth>
      <InputLabel
        sx={{
          bgcolor: "white",
          px: 1,
          lineHeight: 1.25,
          "&.MuiInputLabel-shrink": {
            bgcolor: "white",
          },
        }}
      >
        {label}
      </InputLabel>
      <Select
        defaultValue=""
        {...props}
        sx={(theme) => ({
          ...theme.mixins.inputStyles,
        })}
      >
        {!containHeaders
          ? options.map((opt) => (
              <MenuItem value={opt} key={opt}>
                {opt}
              </MenuItem>
            ))
          : options.map((schools) =>
              schools.map((school, idx) =>
                idx === 0 ? (
                  <ListSubheader key={school}>{school}</ListSubheader>
                ) : (
                  <MenuItem value={`${schools[0]}--${school}`} key={school}>
                    {school}
                  </MenuItem>
                )
              )
            )}
      </Select>
    </FormControl>
  );
};

export default StyledSelect;
