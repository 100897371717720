import {
  AppBar,
  Typography,
  IconButton,
  Toolbar,
  Button,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";

const CertificateHeader = ({ title, download, onClose }) => {
  return (
    <AppBar color="secondary" sx={{ color: "white" }}>
      <Toolbar
        sx={{
          justifyContent: "space-between",
        }}
      >
        <Typography fontWeight={600} variant="h6">
          {title}
        </Typography>
        <Stack direction="row" spacing={4}>
          <Button
            color="inherit"
            onClick={download}
            startIcon={<DownloadIcon />}
          >
            Download PDF
          </Button>
          <IconButton onClick={onClose} color="inherit">
            <CloseIcon />
          </IconButton>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default CertificateHeader;
