import { Stack, Typography } from "@mui/material";
import ImageEl from "../UI/ImageEl";

const StyledRadioBtn = ({ value, isActive = false, onClick }) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      onClick={onClick}
      bgcolor={isActive ? "secondary.main" : "#fff"}
      // borderWidth={isActive ? "none" : "1px"}
      border={isActive ? "none" : 1}
      borderColor="secondary.main"
      borderRadius={2}
      py={1}
      px={4}
      sx={{
        cursor: "pointer",
      }}
    >
      {value.includes("http") ? (
        <ImageEl width={70} height={70} src={value} alt="img" />
      ) : (
        <Typography color={isActive ? "#fff" : "secondary"} fontWeight={700}>
          {value}
        </Typography>
      )}
    </Stack>
  );
};

export default StyledRadioBtn;
