import { styled, TextField, outlinedInputClasses } from "@mui/material";

export default styled((props) => (
  <TextField
    {...props}
    sx={(theme) => ({
      ...theme.mixins.inputStyles,
      [`.${outlinedInputClasses.input}`]: {
        p: 2,
      },
    })}
  />
))({});
