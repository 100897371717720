import { Button, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";

import ImageEl from "../../layout/UI/ImageEl";

const CertificateCard = ({ title, downloadCertificate }) => {
  return (
    <>
      <Grid item xs={3}>
        <Stack
          position="relative"
          height={290}
          bgcolor="#fff"
          boxShadow="0px 5px 25px rgba(0, 0, 0, 0.05)"
          borderRadius={5}
          overflow="hidden"
        >
          <Stack
            boxSizing="border-box"
            textAlign="center"
            py={4}
            px={2}
            height={145}
            bgcolor="secondary.main"
            color="#fff"
          >
            <Typography variant="h6">{title}</Typography>
          </Stack>
          <ImageEl
            sx={{
              width: "25%",
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%,-50%)",
            }}
            src="/assets/certificate-ribbon.svg"
            alt="ribbon"
          />
          <Stack
            position="absolute"
            top="80%"
            left="50%"
            sx={{
              transform: "translate(-50%,-50%)",
            }}
          >
            <Button onClick={downloadCertificate} color="inherit">
              Download Certificate
            </Button>
          </Stack>
        </Stack>
      </Grid>
    </>
  );
};

export default CertificateCard;
