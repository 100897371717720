import { initializeApp } from "firebase/app";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyCJ21RJh-7hNqyX0N3Wm3Hp9cmJFdqkZ1I",
  authDomain: "global-olympiad-zone.firebaseapp.com",
  projectId: "global-olympiad-zone",
  storageBucket: "global-olympiad-zone.appspot.com",
  messagingSenderId: "534676577473",
  appId: "1:534676577473:web:a5a4a587cfdc059f5b4840",
  measurementId: "G-Y2K15NNCTB",
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const auth = getAuth();
export const fbFunctions = getFunctions(app);

// if (process.env.NODE_ENV === "development") {
//   connectAuthEmulator(auth, "http://localhost:9099");
//   connectFirestoreEmulator(db, "localhost", 8080);
//   connectFunctionsEmulator(fbFunctions, "localhost", 5001);
// }
