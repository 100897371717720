import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { db, fbFunctions } from "../../firebase";
import { query, collection, getDocs, where } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import chunk from "lodash.chunk";

export const fetchOlympiads = createAsyncThunk(
  "olympiad/fetchOlympiads",
  async (_, { getState }) => {
    const {
      user: {
        user: { grade: userGrade, subjects: userEnrolledSubjects },
      },
    } = getState();
    const colRef = collection(db, "olympiads");

    let olympiads = [];
    const chunks = await Promise.all(
      chunk(userEnrolledSubjects, 10).map(async (chunk) => {
        const q = query(
          colRef,
          where("subject", "in", chunk),
          where("grades", "array-contains", userGrade),
          where("active", "==", true)
        );
        return await getDocs(q);
      })
    );

    chunks.forEach((chunk) =>
      chunk.docs.forEach((doc) =>
        olympiads.push({
          id: doc.id,
          ...doc.data(),
          publishedAt: doc.data().publishedAt.toDate().toISOString(),
          liveAt: doc.data().liveAt.toDate().toISOString(),
        })
      )
    );

    return olympiads;
  }
);

export const submitOlympiad = createAsyncThunk(
  "olympiad/submitOlympiad",
  async (olympiad, { dispatch }) => {
    const submitOlympiadFn = httpsCallable(fbFunctions, "submitOlympiad");
    try {
      await submitOlympiadFn(olympiad);
      // add olympiadId to participatedIn array in 'User slice'

      // [TODO  olympiad error problem while psuhing to the array of participatedin]
      return { olympiadId: olympiad.olympiadId };
    } catch (err) {
      console.log(err);
    }
  }
);

const olympiad = createSlice({
  name: "olympiad",
  initialState: {
    olympiads: [],
    loading: false,
    fetched: false,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOlympiads.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchOlympiads.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchOlympiads.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.fetched = true;

      state.olympiads = payload;
    });
  },
});

export default olympiad.reducer;
