import StyledFilledInput from "../../layout/Input/StyledFilledInput";

const Login = ({ email, password, handleChange }) => {
  return (
    <>
      <StyledFilledInput
        name="email"
        value={email}
        onChange={handleChange}
        fullWidth
        placeholder="Email"
      />
      <StyledFilledInput
        type="password"
        name="password"
        value={password}
        onChange={handleChange}
        fullWidth
        placeholder="Password"
      />
    </>
  );
};

export default Login;
