import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/userSlice";
import globalReducer from "./slices/globalSlice";
import olympiadReducer from "./slices/olympiadSlice";

export default configureStore({
  reducer: {
    user: userReducer,
    global: globalReducer,
    olympiad: olympiadReducer,
  },
});
